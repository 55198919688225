<template>
    <div class="main-wrapper">
        <el-scrollbar class="table-content">
            <div class="top-tab">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="customBreadcrumb">
                    <el-breadcrumb-item>实操训练</el-breadcrumb-item>
                    <el-breadcrumb-item>答题详情</el-breadcrumb-item>
                    <el-breadcrumb-item>查看详情</el-breadcrumb-item>
                </el-breadcrumb>
                <el-button type="primary" @click="toBack" class="go-back">返回</el-button>
            </div>
            <el-table :data="promotionList" v-if="promotionList.length > 0" class="customTable">
                <el-table-column prop="exam_content" label="考试内容"></el-table-column>
                <el-table-column label="学生提交答案" align="left">
                    <template slot-scope="scope">
                        <div class="link-box">
                            <el-link type="primary" v-if="scope.row.exam_content =='软文推广'" :underline="false"
                                     @click="viewArticle(scope.row)"
                                     class="view-detail">查看详情
                            </el-link>
                            <el-link type="primary" v-if="scope.row.exam_content =='视频推广'" :underline="false"
                                     @click="downloadFile(scope.row)"
                                     class="view-detail">下载素材
                            </el-link>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="softList" v-if="softList.length > 0" class="customTable">
                <el-table-column prop="content" label="考试内容">
                    <template slot-scope="scope">
                        <span v-if="scope.$index == 0">{{scope.row.exam_content}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="分析问题">
                    <template slot-scope="scope">
                        <span>{{scope.row.analysis_problem}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="学生提交答案" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.stu_answer}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="score" label="正确答案" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.answer}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="score" :label="'得分（'+soft_total+'分）'" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.score}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="liveList" v-if="liveList.length > 0" class="customTable">
                <el-table-column prop="content" label="考试内容" width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.$index == 0">{{scope.row.content}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="point" label="考点" width="200">
                    <template slot-scope="scope">
                        <span>{{scope.row.exam_site}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="exam_content" label="填写内容" width="440"></el-table-column>
                <el-table-column label="学生提交答案" align="left" width="330">
                    <template slot-scope="scope">
                        <span class="show-text">{{scope.row.stu_answer}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="score" label="正确答案" align="left">
                    <template slot-scope="scope">
                        <span class="show-text">{{scope.row.answer}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="score" :label="'得分（'+live_total+'分）'" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.score}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-scrollbar>
        <el-dialog title="评分详情"
                   :visible.sync="scoreDetailDialog" width="830px" @close="handleClose"
                   custom-class="green-dialog goods-score-dialog">
            <div class="upload-file-content">
                <el-table :data="scoreList" class="customTable goods-text-table" style="width: 100%; flex: 1;"
                          height="1%"
                          :header-cell-style="{fontWeight: 'normal', height: '40px', color: '#333', background: '#F2F3FA',fontSize: '14px'}"
                          :cell-style="{fontSize: '14px',color: '#333333',height: '40px'}">
                    <el-table-column prop="nickname" label="评委名称" align="left"></el-table-column>
                    <el-table-column prop="score" label="分数" align="center"></el-table-column>
                    <el-table-column prop="update_time" label="评分时间" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.update_time}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination class="pages-center" :total="total" @current-change="handleCurrentChange"/>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import pagination from "@/components/pagination";
    import {teacheropTrainData} from "@/utils/apis"

    export default {
        name: "NetworkPromotion",
        components: {
            pagination
        },
        data() {
            return {
                examId: this.$route.query.examId,
                studentId: this.$route.query.id,
                type: this.$route.query.type || null,
                promotionList: [],
                scoreDetailDialog: false,
                scoreList: [],
                answerList: [],
                total: 0,
                currentPageNum: 1,
                softInfo: {},
                softList: [],
                liveList: [],
                liveInfo: {},
                prompte_total: 0,
                live_total: 0,
                soft_total: 0,
                softType: 3,
            }
        },
        created() {
            this.getList();
        },
        methods: {
            toBack() {
                this.$router.go(-1)
            },
            getList() {
                let param = {
                    train_id: this.$route.query.train_id
                }
                teacheropTrainData(param).then(res => {
                    this.softInfo = res.data.promote;
                    this.liveInfo = res.data.live;
                    if (res.data.user_analysis) {
                        this.softList = res.data.user_analysis.data
                    }
                    this.promotionList = this.softInfo ? this.softInfo.data : []
                    this.liveList = this.liveInfo ? this.liveInfo.data : []
                    this.formatTotal();
                    console.log(111)
                }).catch(err => {
                    console.error(err)
                })
            },
            formatTotal() {
                this.promotionList.forEach(item => {
                    this.prompte_total += Number(item.score)
                })
                this.softList.forEach(item => {
                    this.soft_total += Number(item.score)
                })
                this.liveList.forEach(item => {
                    this.live_total += Number(item.score)
                })
            },
            viewArticle(row) {
                this.$router.push({
                    path: '/teacher/articleDetail',
                    query: {
                        article_id: row.id
                    }
                })
            },
            //下载文件
            downloadFile(row) {
                window.open(row.download_path, '_blank')
            },
            handleClose() {
                this.scoreDetailDialog = false
            },
            //切换分页
            handleCurrentChange(val) {
                this.currentPageNum = val;
                this.getScore();
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        padding: 0 20px 20px 20px;
        box-sizing: border-box;

        .table-content {
            height: 100%;

            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .customTable {
                width: 100%;
                flex: 1;
                margin-top: 20px;

                .show-text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .top-tab {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 2px solid #F1F5FF;

                .go-back {
                    margin-bottom: 12px;
                }
            }
        }

        .upload-file-content {
            height: 100%;

            .pages-center {
                margin-bottom: 0;
            }
        }
    }
</style>